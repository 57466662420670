import React, { useState } from 'react';
import { ArrowRight, ChevronLeft, ChevronRight } from 'lucide-react';
import { Dialog } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import { useNavigate } from 'react-router-dom';
import SignupModal from './SignupModal';

const slides = [
    '/soon.png',
  '/slide2.png',
  '/slide3.png',
  '/slide4.png',
  '/slide5.png'
];


const ImageCarousel = ({ currentSlide, setCurrentSlide }) => {
  const nextSlide = () => {
    setCurrentSlide((prev) => (prev + 1) % slides.length);
  };

  const previousSlide = () => {
    setCurrentSlide((prev) => (prev - 1 + slides.length) % slides.length);
  };

  return (
    <div className="flex-1 flex items-center justify-center relative">
      {/* Coming Soon indicator */}
      <div className="absolute top-0 left-1/2 -translate-x-1/2 py-2">
        <span className="text-sm font-medium text-gray-400 bg-white/80 backdrop-blur-sm px-4 py-1.5 rounded-full border border-gray-100">
          Coming Soon
        </span>
      </div>
  
      <div className="w-full max-w-5xl mx-auto px-4 md:px-8 relative">
    {/* Navigation buttons moved slightly outside the main container */}
    <button 
      onClick={() => setCurrentSlide((prev) => (prev - 1 + slides.length) % slides.length)}
      className="absolute -left-6 top-1/2 -translate-y-1/2 p-2 rounded-full bg-black/5 hover:bg-black/10 transition-all hover:scale-105"
    >
      <ChevronLeft size={24} />
    </button>
  
        <div className="relative aspect-[16/10] bg-gradient-to-b from-pink-50 to-white rounded-2xl shadow-sm overflow-hidden">
          <div className="absolute inset-0 bg-gradient-to-tr from-pink-50/50 via-transparent to-transparent" />
          
          <img
            src={slides[currentSlide]}
            alt={`Slide ${currentSlide + 1}`}
            className="w-full h-full object-contain p-6 relative z-10"
          />
          <span className="absolute top-6 right-6 text-sm font-medium text-gray-400 bg-white/80 backdrop-blur-sm z-10 px-3 py-1 rounded-full">
            {currentSlide + 1} / {slides.length}
          </span>
        </div>
  
        <button 
      onClick={() => setCurrentSlide((prev) => (prev + 1) % slides.length)}
      className="absolute -right-6 top-1/2 -translate-y-1/2 p-2 rounded-full bg-black/5 hover:bg-black/10 transition-all hover:scale-105"
    >
      <ChevronRight size={24} />
    </button>
  </div>
    </div>
  );
};


const LandingPage = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isSignupOpen, setIsSignupOpen] = useState(false); // Add this line
  const navigate = useNavigate();
  

  return (
    <div className="min-h-screen bg-gradient-to-b from-pink-50/30 via-white to-white">
      <div className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80">
        <div className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-pink-50 to-pink-100 opacity-20 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]" />
      </div>

      <header className="absolute inset-x-0 top-0 z-50">
        <nav className="mx-auto max-w-7xl flex items-center justify-between p-6 lg:px-8" aria-label="Global">
          <div className="flex lg:flex-1">
            <a href="/" className="-m-1.5 p-1.5">
              <span className="sr-only">Rentr</span>
              <div className="text-3xl font-bold tracking-tight">
                rentr<span className="text-pink-400">.</span>
              </div>              
            </a>
          </div>
          <div className="flex lg:hidden">
            <button
              type="button"
              className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
              onClick={() => setMobileMenuOpen(true)}
            >
              <span className="sr-only">Open main menu</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="hidden lg:flex lg:gap-4">
          <a 
  href="https://rentrdemo-098dccc0eee7.herokuapp.com/" 
  className="group px-8 py-3 text-sm font-semibold text-white bg-black rounded-full hover:bg-gray-800 transition-all hover:shadow-lg hover:-translate-y-0.5 flex items-center gap-2 relative overflow-hidden"
>
  <span className="absolute inset-0 bg-gradient-to-r from-pink-400/0 via-pink-400/5 to-pink-400/0 opacity-0 group-hover:opacity-100 transition-opacity" />
  <span className="relative flex items-center">
    Try Demo
  </span>
</a>
<a 
  onClick={(e) => {
    e.preventDefault();
    setIsSignupOpen(true);
  }}
  href="/signup" 
  className="group px-8 py-3 text-sm font-semibold text-black border border-black/80 rounded-full hover:bg-gray-50 transition-all hover:shadow-lg hover:-translate-y-0.5 relative overflow-hidden"
>
  <span className="absolute inset-0 bg-gradient-to-r from-pink-400/0 via-pink-400/5 to-pink-400/0 opacity-0 group-hover:opacity-100 transition-opacity" />
  <span className="relative group-hover:text-pink-500 transition-colors">Early Access</span>
</a>
          </div>
        </nav>
        
        {/* Mobile menu - keeping  the same but with updated styling */}
        <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
          <div className="fixed inset-0 z-50" />
          <Dialog.Panel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white/80 backdrop-blur-sm px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
            {/* ... rest of mobile menu code ... */}
          </Dialog.Panel>
        </Dialog>
      </header>

      <main className="relative pt-28 pb-16 min-h-screen flex flex-col">
        {/* Carousel */}
        <ImageCarousel currentSlide={currentSlide} setCurrentSlide={setCurrentSlide} />

        {/* Slide indicators */}
        <div className="flex justify-center gap-3 py-12">
          {slides.map((_, index) => (
            <button
              key={index}
              onClick={() => setCurrentSlide(index)}
              className={`w-2 h-2 rounded-full transition-all hover:scale-110 ${
                currentSlide === index 
                  ? 'bg-pink-400 scale-110' 
                  : 'bg-gray-200 hover:bg-gray-300'
              }`}
            />
          ))}
        </div>

        {/* Bottom CTAs */}
        <div className="flex justify-center gap-4 mt-auto">
  <a 
    href="https://rentrdemo-098dccc0eee7.herokuapp.com/" 
    className="group px-8 py-3 text-sm font-semibold text-white bg-black rounded-full hover:bg-gray-800 transition-all hover:shadow-lg hover:-translate-y-0.5 flex items-center gap-2 relative overflow-hidden"
  >
    <span className="absolute inset-0 bg-gradient-to-r from-pink-400/0 via-pink-400/5 to-pink-400/0 opacity-0 group-hover:opacity-100 transition-opacity" />
    <span className="relative flex items-center gap-2">
      Try Demo <ArrowRight size={16} className="group-hover:text-pink-400 transition-colors" />
    </span>
  </a>
<a 
  onClick={(e) => {
    e.preventDefault();
    setIsSignupOpen(true);
  }}
  href="/signup" 
  className="group px-8 py-3 text-sm font-semibold text-black border border-black/80 rounded-full hover:bg-gray-50 transition-all hover:shadow-lg hover:-translate-y-0.5 relative overflow-hidden"
>
  <span className="absolute inset-0 bg-gradient-to-r from-pink-400/0 via-pink-400/5 to-pink-400/0 opacity-0 group-hover:opacity-100 transition-opacity" />
  <span className="relative group-hover:text-pink-500 transition-colors">Early Access</span>
</a>
</div>

      </main>
      <SignupModal isOpen={isSignupOpen} setIsOpen={setIsSignupOpen} />
    </div>
  );
};



export default LandingPage;