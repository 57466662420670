import React, { Fragment, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { CheckCircle } from 'lucide-react';

// Moved these outside as named exports if needed elsewhere
export const userTypes = [
  { id: 'tenant', name: 'Tenant', description: 'I want to pay rent flexibly' },
  { id: 'landlord', name: 'Landlord', description: 'I want to offer flexible rent' },
  { id: 'other', name: 'Other', description: 'I\'m interested in partnering' },
];

export const regions = [
  { id: 'uk', name: 'United Kingdom', flag: '🇬🇧' },
  { id: 'eu', name: 'European Union', flag: '🇪🇺' },
  { id: 'us', name: 'United States', flag: '🇺🇸' },
  { id: 'global', name: 'Rest of World', flag: '🌍' },
];

// Remove 'const' here since we're exporting directly
export default function SignupModal({ isOpen, setIsOpen }) {
  const [step, setStep] = useState(1);
  const [userType, setUserType] = useState(null);
  const [region, setRegion] = useState(null);
  const [email, setEmail] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState(null);


  const handleSubmit = async (e) => {
    e.preventDefault();
    
    try {
      const response = await fetch('https://formspree.io/f/xgvekark', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email,
          userType,
          region,
          submittedAt: new Date().toISOString(),
        }),
      });
  
      if (response.ok) {
        setSubmitted(true);
      } else {
        // Handle error - maybe add an error state
        console.error('Submission failed');
      }
    } catch (error) {
      console.error('Submission error:', error);
    }
  };

  const resetAndClose = () => {
    setStep(1);
    setUserType(null);
    setRegion(null);
    setEmail('');
    setSubmitted(false);
    setIsOpen(false);
  };

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={resetAndClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500/25 backdrop-blur-sm transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform rounded-2xl bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div className="absolute right-0 top-0 pr-4 pt-4">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none"
                    onClick={resetAndClose}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>

                {submitted ? (
                  <div className="text-center py-12">
                    <CheckCircle className="mx-auto h-12 w-12 text-pink-400" />
                    <h2 className="mt-4 text-2xl font-semibold text-gray-900">You're on the list!</h2>
                    <p className="mt-2 text-gray-600">We'll be in touch when we launch in your area</p>
                    <button
                      onClick={resetAndClose}
                      className="mt-6 px-4 py-2 text-sm font-medium text-gray-700 hover:text-gray-900"
                    >
                      Close
                    </button>
                  </div>
                ) : (
                  <div className="space-y-4">
                    {/* Progress indicator */}
                    <div className="mb-8 flex justify-center gap-2">
                      {[1, 2, 3].map((i) => (
                        <div
                          key={i}
                          className={`h-2 w-2 rounded-full transition-all ${
                            i <= step ? 'bg-pink-400' : 'bg-gray-200'
                          }`}
                        />
                      ))}
                    </div>

                    {step === 1 && (
                      <div className="space-y-4">
                        <Dialog.Title as="h3" className="text-2xl font-semibold text-center text-gray-900">
                          I am a...
                        </Dialog.Title>
                        <div className="space-y-2">
                          {userTypes.map((type) => (
                            <button
                              key={type.id}
                              onClick={() => {
                                setUserType(type.id);
                                setStep(2);
                              }}
                              className={`w-full p-4 text-left rounded-xl border-2 transition-all hover:-translate-y-0.5 hover:shadow-md ${
                                userType === type.id
                                  ? 'border-pink-400 bg-pink-50'
                                  : 'border-gray-200 hover:border-pink-200'
                              }`}
                            >
                              <div className="font-medium text-gray-900">{type.name}</div>
                              <div className="text-sm text-gray-500">{type.description}</div>
                            </button>
                          ))}
                        </div>
                      </div>
                    )}

                    {step === 2 && (
                      <div className="space-y-4">
                        <Dialog.Title as="h3" className="text-2xl font-semibold text-center text-gray-900">
                          Where are you located?
                        </Dialog.Title>
                        <div className="grid grid-cols-2 gap-3">
                          {regions.map((reg) => (
                            <button
                              key={reg.id}
                              onClick={() => {
                                setRegion(reg.id);
                                setStep(3);
                              }}
                              className={`p-4 text-center rounded-xl border-2 transition-all hover:-translate-y-0.5 hover:shadow-md ${
                                region === reg.id
                                  ? 'border-pink-400 bg-pink-50'
                                  : 'border-gray-200 hover:border-pink-200'
                              }`}
                            >
                              <div className="text-2xl mb-2">{reg.flag}</div>
                              <div className="font-medium text-gray-900">{reg.name}</div>
                            </button>
                          ))}
                        </div>
                      </div>
                    )}

                    {step === 3 && (
                      <div className="space-y-4">
                        <Dialog.Title as="h3" className="text-2xl font-semibold text-center text-gray-900">
                          Join the waitlist
                        </Dialog.Title>
                        <form onSubmit={handleSubmit} className="space-y-4">
                          <div>
                            <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                              Email address
                            </label>
                            <input
                              type="email"
                              id="email"
                              required
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                              className="mt-1 block w-full rounded-lg border-gray-300 shadow-sm focus:border-pink-400 focus:ring-pink-400"
                              placeholder="you@example.com"
                            />
                          </div>
                          <button
  type="submit"
  disabled={isSubmitting}
  className={`w-full px-8 py-3 text-sm font-semibold text-white bg-black rounded-full transition-all relative overflow-hidden group 
    ${isSubmitting 
      ? 'opacity-75 cursor-not-allowed' 
      : 'hover:bg-gray-800 hover:shadow-lg hover:-translate-y-0.5'}`}
>
  <span className="absolute inset-0 bg-gradient-to-r from-pink-400/0 via-pink-400/5 to-pink-400/0 opacity-0 group-hover:opacity-100 transition-opacity" />
  <span className="relative flex items-center justify-center gap-2">
    {isSubmitting ? (
      <>
        <svg className="animate-spin h-4 w-4" viewBox="0 0 24 24">
          <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" fill="none" />
          <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
        </svg>
        Submitting...
      </>
    ) : (
      'Join Waitlist'
    )}
  </span>
</button>
{/* Add error message display */}
{submitError && (
  <p className="mt-2 text-sm text-red-600">
    {submitError}
  </p>
)}                        </form>
                      </div>
                    )}

                    {/* Back button when not on first step */}
                    {step > 1 && (
                      <button
                        onClick={() => setStep(step - 1)}
                        className="mt-4 text-sm text-gray-500 hover:text-gray-700"
                      >
                        ← Back
                      </button>
                    )}
                  </div>
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

